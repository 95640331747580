import { parseOptions } from "@/components/Charts/optionHelpers";
import { getFormattedBigNumber, getFormattedNormalNumber, getFormattedRate, getPercentage } from "@/utils";
export const Charts = {
  mode: "light", //(themeMode) ? themeMode : 'light';
  fonts: {
    base: "open sans"
  },
  colors: {
    gray: {
      100: "#f6f9fc",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#8898aa",
      700: "#525f7f",
      800: "#32325d",
      900: "#212529"
    },
    theme: {
      default: "#172b4d",
      primary: "#5e72e4",
      secondary: "#f4f5f7",
      info: "#11cdef",
      success: "#2dce89",
      danger: "#f5365c",
      warning: "#fb6340"
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent"
  }
};

function chartOptions(Chart) {
  let { colors, mode, fonts } = Charts;
  // Options
  let options = {
    defaults: {
      global: {
        responsive: true,
        maintainAspectRatio: false,
        defaultColor: mode == "dark" ? colors.gray[700] : colors.gray[600],
        defaultFontColor: mode == "dark" ? colors.gray[700] : colors.gray[600],
        defaultFontFamily: fonts.base,
        defaultFontSize: 13,
        layout: {
          padding: 0
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            usePointStyle: true,
            padding: 16
          }
        },
        elements: {
          point: {
            radius: 3,
            backgroundColor: colors.theme["primary"]
          },
          line: {
            tension: 0.4,
            borderWidth: 2,
            borderColor: colors.theme["primary"],
            backgroundColor: colors.transparent,
            borderCapStyle: "rounded"
          },
          rectangle: {
            backgroundColor: colors.theme["warning"]
          },
          arc: {
            backgroundColor: colors.theme["primary"],
            borderColor: mode == "dark" ? colors.gray[800] : colors.white,
            borderWidth: 1
          }
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return (data.datasets[0].prefix ? data.datasets[0].prefix : " ") + getFormattedNormalNumber(tooltipItem.yLabel, 0);
            }
          }
        }
      },
      doughnut: {
        cutoutPercentage: 83,
        legendCallback: function (chart) {
          let data = chart.data;
          let content = "";

          data.labels.forEach(function (label, index) {
            let bgColor = data.datasets[0].backgroundColor[index];

            content += '<span class="chart-legend-item">';
            content +=
              '<i class="chart-legend-indicator" style="background-color: ' +
              bgColor +
              '"></i>';
            content += label;
            content += "</span>";
          });

          return content;
        }
      }
    }
  };

  // yAxes
  Chart.scaleService.updateScaleDefaults("linear", {
    position: "right",
    gridLines: {
      color: mode == "dark" ? colors.gray[600] : colors.gray[200],
      drawBorder: false,
      drawTicks: false,
      lineWidth: 1,
      zeroLineWidth: 1,
      zeroLineColor: mode == "dark" ? colors.gray[600] : colors.gray[200]
    },
    ticks: {
      beginAtZero: true,
      padding: 10,
      callback: function (value) {
        return getFormattedBigNumber(value);
      }
    }
  });

  // xAxes
  Chart.scaleService.updateScaleDefaults("category", {
    gridLines: {
      drawBorder: true,
      drawOnChartArea: true,
      drawTicks: false
    },
    ticks: {
      padding: 20
    },
    maxBarThickness: 25
  });

  return options;
}

export function initGlobalOptions(Chart) {
  parseOptions(Chart, chartOptions(Chart));
}

export const basicOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  responsive: true
};

export let blueChartOptions = {
  scales: {
    yAxes: [
      {
        gridLines: {
          color: Charts.colors.gray[700],
          zeroLineColor: Charts.colors.gray[700]
        },
        ticks: {
          callback: function (value) {
            return getFormattedBigNumber(value);
          }
        }
      }
    ]
  }
};

export let areaChartOptions = {
  type: "line",
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: true,
          color: Charts.colors.gray[200]
        },
        ticks: {
          stepSize: 1,
          callback: function (value, index, values) {
            const chartElement = document.querySelector("#chart-container canvas");
            if (!chartElement) return value;
            const chartWidth = chartElement.clientWidth;
            const ticksNum = values.length;
            const tickWidth = chartWidth / (ticksNum ? ticksNum : 1);
            if (index < 1 || tickWidth > 90) {
              return value;
            }
            const previousLabel = values[index - 1].split(" ");
            const currentLabel = value.split(" ");

            let equalPartIndex = -1;
            for (let i = 0; i < currentLabel.length; i++) {
              if (previousLabel[i] === currentLabel[i]) {
                equalPartIndex = i;
                continue;
              }
              break;
            }
            if (equalPartIndex < 0) return value;
            let resolvedLabel = "";
            for (let i = equalPartIndex + 1; i < currentLabel.length; i++) {
              resolvedLabel += resolvedLabel
                ? ` ${currentLabel[i]}`
                : currentLabel[i];
            }
            return resolvedLabel;
          }
        }
      }
    ],
    yAxes: [
      {
        display: true,
        gridLines: {
          color: Charts.colors.gray[200],
          zeroLineColor: Charts.colors.gray[300],
          display: true
        },
        ticks: {
          callback: function (value) {
            return getFormattedBigNumber(value);
          }
        }
      }
    ]
  }
};
export let kpiChartOptions = {
  type: "line",
  ...basicOptions,
  elements: {
    point: {
      radius: 0,

    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: true,
          color: Charts.colors.gray[200]
        },
        ticks: {
          display: false,
          stepSize: 1,
          callback: function (value, index, values) {
            const chartElement = document.querySelector("#chart-container canvas");
            if (!chartElement) return value;
            const chartWidth = chartElement.clientWidth;
            const ticksNum = values.length;
            const tickWidth = chartWidth / (ticksNum ? ticksNum : 1);
            if (index < 1 || tickWidth > 90) {
              return value;
            }
            const previousLabel = values[index - 1].split(" ");
            const currentLabel = value.split(" ");

            let equalPartIndex = -1;
            for (let i = 0; i < currentLabel.length; i++) {
              if (previousLabel[i] === currentLabel[i]) {
                equalPartIndex = i;
                continue;
              }
              break;
            }
            if (equalPartIndex < 0) return value;
            let resolvedLabel = "";
            for (let i = equalPartIndex + 1; i < currentLabel.length; i++) {
              resolvedLabel += resolvedLabel
                ? ` ${currentLabel[i]}`
                : currentLabel[i];
            }
            return resolvedLabel;
          }
        }
      }
    ],
    yAxes: [
      {
        display: true,
        gridLines: {
          color: Charts.colors.gray[200],
          zeroLineColor: Charts.colors.gray[300],
          display: true
        },
        ticks: {
          display: false,
          callback: function (value) {
            return getFormattedBigNumber(value);
          }
        }
      }
    ]
  }
};

export let metricsChartOptions = {
  type: "line",
  ...basicOptions,
  elements: {
    point: {
      radius: 1,

    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        position: 'left',
        gridLines: {
          display: true,
          color: Charts.colors.gray[200]
        },
        ticks: {
          display: true,
          stepSize: 1,
          callback: function (value, index, values) {
            const chartElement = document.querySelector("#chart-container canvas");
            if (!chartElement) return value;
            const chartWidth = chartElement.clientWidth;
            const ticksNum = values.length;
            const tickWidth = chartWidth / (ticksNum ? ticksNum : 1);
            if (index < 1 || tickWidth > 90) {
              return value;
            }
            const previousLabel = values[index - 1].split(" ");
            const currentLabel = value.split(" ");

            let equalPartIndex = -1;
            for (let i = 0; i < currentLabel.length; i++) {
              if (previousLabel[i] === currentLabel[i]) {
                equalPartIndex = i;
                continue;
              }
              break;
            }
            if (equalPartIndex < 0) return value;
            let resolvedLabel = "";
            for (let i = equalPartIndex + 1; i < currentLabel.length; i++) {
              resolvedLabel += resolvedLabel
                ? ` ${currentLabel[i]}`
                : currentLabel[i];
            }
            return resolvedLabel;
          }
        }
      }
    ],
    yAxes: [
      {
        display: true,
        position: 'left',
        stepSize: 1,
        gridLines: {
          color: Charts.colors.gray[200],
          zeroLineColor: Charts.colors.gray[300],
          display: true
        },
        ticks: {
          beginAtZero: false,
          display: true,
          callback: function (value) {
            return getFormattedBigNumber(value);
          }
        }
      }
    ]
  }
};

export let metricsChartOptions2 = {
  type: "line",
  ...basicOptions,
  tooltips: {
    enabled: true,
    mode: "index",
    intersect: false,
    callbacks: {
      label: function (tooltipItem, data) {
        return (data.datasets[0].prefix ? data.datasets[0].prefix : " ") + getPercentage(tooltipItem.yLabel, 0);
      }
    }
  },
  elements: {
    point: {
      radius: 1,

    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        position: 'left',
        gridLines: {
          display: true,
          color: Charts.colors.gray[200]
        },
        ticks: {
          display: true,

          stepSize: 1,
          callback: function (value, index, values) {
            const chartElement = document.querySelector("#chart-container canvas");
            if (!chartElement) return value;
            const chartWidth = chartElement.clientWidth;
            const ticksNum = values.length;
            const tickWidth = chartWidth / (ticksNum ? ticksNum : 1);
            if (index < 1 || tickWidth > 90) {
              return value;
            }
            const previousLabel = values[index - 1].split(" ");
            const currentLabel = value.split(" ");

            let equalPartIndex = -1;
            for (let i = 0; i < currentLabel.length; i++) {
              if (previousLabel[i] === currentLabel[i]) {
                equalPartIndex = i;
                continue;
              }
              break;
            }
            if (equalPartIndex < 0) return value;
            let resolvedLabel = "";
            for (let i = equalPartIndex + 1; i < currentLabel.length; i++) {
              resolvedLabel += resolvedLabel
                ? ` ${currentLabel[i]}`
                : currentLabel[i];
            }
            return resolvedLabel;
          }
        }
      }
    ],
    yAxes: [
      {
        display: true,
        position: 'left',
        gridLines: {
          color: Charts.colors.gray[200],
          zeroLineColor: Charts.colors.gray[300],
          display: true
        },
        ticks: {
          display: true,
          beginAtZero: false,

          callback: function (value) {
            return getPercentage(value);
          }
        }
      }
    ]
  }
};

export let lineChartOptionsBlue = {
  ...basicOptions,
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent"
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9e9e9e"
        }
      }
    ],

    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#9e9e9e"
        }
      }
    ]
  }
};

export let barChartOptionsGradient = {
  ...basicOptions,
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: "rgba(253,93,147,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9e9e9e"
        }
      }
    ],

    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: "rgba(253,93,147,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#9e9e9e"
        }
      }
    ]
  }
};

export let pieChartOptions = {
  ...basicOptions,
  cutoutPercentage: 70,
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },

  scales: {
    yAxes: [
      {
        display: 0,
        ticks: {
          display: false
        },
        gridLines: {
          drawBorder: false,
          zeroLineColor: "transparent",
          color: "rgba(255,255,255,0.05)"
        }
      }
    ],

    xAxes: [
      {
        display: 0,
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(255,255,255,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          display: false
        }
      }
    ]
  }
};

export let purpleChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent"
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ],

    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(225,78,202,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ]
  }
};

export let orangeChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent"
        },
        ticks: {
          suggestedMin: 50,
          suggestedMax: 110,
          padding: 20,
          fontColor: "#ff8a76"
        }
      }
    ],

    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(220,53,69,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#ff8a76"
        }
      }
    ]
  }
};
export let greenChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent"
        },
        ticks: {
          suggestedMin: 50,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9e9e9e"
        }
      }
    ],

    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(0,242,195,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#9e9e9e"
        }
      }
    ]
  }
};

export let barChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 120,
          padding: 20,
          fontColor: "#9e9e9e"
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#9e9e9e"
        }
      }
    ]
  }
};

export let doughnutChartOption = {
  ...basicOptions,
  legend: {
    display: true,
    labels: {
      generateLabels: (chart) => {
        const datasets = chart.data.datasets;
        return datasets[0].data.map((data, i) => ({
          text: `${chart.data.labels[i]} ${data}%`,
          fillStyle: datasets[0].backgroundColor[i],
        }))
      }
    }
  }
}
